
import Vue from 'vue';
import {Component, VModel} from 'vue-property-decorator';
import RichTextEditor from '~/components/common/rich-text-editor.vue';

@Component({
  components: {RichTextEditor},
})
export default class FeedbackDialog extends Vue {
  @VModel({type: Boolean, default: false}) show: boolean;

  form = {
    title: '',
    text: '',
  };

  async submit() {
    try {
      await this.$api.post('/api/feedback', this.form);
      this.$notify.success(this.$t('msg.thankYouForFeedback'));
      this.clear();
      this.show = false;
    } catch (e) {
      this.$notify.error(e);
    }
  }

  clear() {
    this.form.title = '';
    this.form.text = '';
  }
}
