
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import FeedbackDialog from '~/components/dialogs/feedback-dialog.vue';
import AppFooter from '~/components/layout/app-footer.vue';
import AppHeader from '~/components/layout/app-header.vue';
import BottomNavigation from '~/components/layout/bottom-navigation.vue';
import SideNavigation from '~/components/layout/side-navigation.vue';
import LoadingIndicator from '~/components/common/loading-indicator.vue';

@Component({
  components: {AppFooter, LoadingIndicator, AppHeader, SideNavigation, BottomNavigation, FeedbackDialog},
})
export default class UserLayout extends Vue {
  get pageWrapStyle() {
    return {
      minHeight: 'calc(100% - 36px)',
    };
  }
}
