
import Vue from 'vue';
import {Component, VModel} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class LoginDialog extends Vue {
  @VModel({type: Boolean, default: false}) show: boolean;

  form = {
    email: '',
    password: '',
  };

  get title() {
    return this.$t('action.login');
  }

  async submit() {
    try {
      await this.$store.dispatch('auth/login', this.form);
      this.clearAndClose();
    } catch (e) {
      this.$notify.error(e);
    }
  }

  clearAndClose() {
    this.clear();
    this.show = false;
  }

  clear() {
    this.form.email = '';
    this.form.password = '';
  }
}
