
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import UserMenu from '~/components/layout/user-menu.vue';

@Component({
  components: {UserMenu},
})
export default class UserHeader extends Vue {
  onNavIconClick() {
    this.$store.dispatch('app/toggleNavigation');
  }
}
