export enum ContactPoint {
  FEET,
  KNEES,
  HANDS,
  ARMS,
  HEAD,
  HIP,
  SHINS,
  SHOULDERS,
}
