
import Vue from 'vue';
import {Component, VModel} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class RegisterDialog extends Vue {
  @VModel({type: Boolean, default: false}) show: boolean;
  form = {
    username: '',
    email: '',
    password: '',
  };

  get title() {
    return this.$t('action.register');
  }

  async submit() {
    try {
      await this.$api.post('/api/auth/register', this.form);
      this.clearAndClose();
    } catch (e) {
      this.$notify.error(e);
    }
  }

  clearAndClose() {
    this.clear();
    this.show = false;
  }

  clear() {
    this.form.username = '';
    this.form.email = '';
    this.form.password = '';
  }
}
