export enum FlyerPosition {
  STANDING = 'standing',
  SITTING = 'sitting',
  ON_SHOULDERS = 'on_shoulders',
  LYING_ON_BACK = 'lying_on_back',
  LYING_ON_BELLY = 'lying_on_belly',
  LYING_ON_SIDE = 'lying_on_side',
  ON_HANDS = 'on_hands',
  KNEELING = 'kneeling',
  ON_HEAD = 'on_head',
  ON_THIGHS_HEADOVER = 'on_thighs_headover',
  ON_ARMS = 'on_arms',
  DIVERSE = 'diverse',
}
