
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
export default class UserMenu extends Vue {
  get user() {
    return this.$store.state.auth.user;
  }

  get avatarStyle() {
    return this.$vuetify.breakpoint.smAndUp ? {marginLeft: '-4px', marginRight: '8px'} : {};
  }

  get items() {
    return [
      {
        icon: 'mdi-account',
        title: this.$tc('p.profile'),
        to: {name: 'profile'},
      },
      {
        icon: 'mdi-format-list-bulleted ',
        title: this.$tc('label.myLists'),
        to: {name: 'my-lists'},
      },
      {
        icon: 'mdi-bookmark-multiple ',
        title: this.$tc('label.myMarks'),
        to: {name: 'my-marks'},
      },
      {
        icon: 'mdi-calendar ',
        title: this.$tc('label.myJams'),
        to: {name: 'my-jams'},
        condition: this.$store.state.user.jams.length > 0,
      },
      {
        icon: 'mdi-power',
        title: this.$t('action.logout'),
        onClick: async () => {
          await this.$store.dispatch('auth/logout');
        },
      },
    ].filter(item => item.condition === undefined || item.condition === true);
  }
}
