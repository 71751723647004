
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
export default class SideNavigation extends Vue {
  isGroupOpen = {
    dictionary: false,
    apps: false,
    events: false,
    admin: false,
  };

  mounted() {
    this.updateGroupOpenState();
  }

  updateGroupOpenState() {
    const path = window.location.pathname;
    if (this.startsWithAny(path, ['/poses', '/flows', '/skills', '/images', '/videos'])) {
      this.isGroupOpen.dictionary = true;
    }
    if (this.startsWithAny(path, ['/apps'])) {
      this.isGroupOpen.apps = true;
    }
    if (this.startsWithAny(path, ['/admin'])) {
      this.isGroupOpen.admin = true;
    }
  }

  get items() {
    return [
      {title: this.$t('label.home'), icon: 'mdi-home', route: {name: 'home'}, exact: true, condition: this.$store.state.auth.isSignedIn},
      {
        title: this.$t('label.dictionary'), icon: 'mdi-book-open-variant', open: this.isGroupOpen.dictionary, children: [
          {title: this.$tc('p.pose', 2), icon: 'mdi-human', route: {name: 'poses'}, exact: false},
          {title: this.$tc('p.flow', 2), icon: 'mdi-washing-machine', route: {name: 'flows'}, exact: false},
          {title: this.$tc('p.skill', 2), icon: 'mdi-magic-staff', route: {name: 'skills'}, exact: false},
          {title: this.$tc('p.image', 2), icon: 'mdi-image', route: {name: 'images'}, exact: false},
          {title: this.$tc('p.video', 2), icon: 'mdi-video', route: {name: 'videos'}, exact: false},
        ],
      },
      {
        title: this.$tc('p.app', 2), icon: 'mdi-gamepad-variant', open: this.isGroupOpen.apps, children: [
          {title: this.$t('label.flowGenerator'), icon: 'mdi-slot-machine', route: {name: 'flow-generator'}, exact: false},
          {title: this.$t('label.acroulette'), icon: 'mdi-poker-chip', route: {name: 'acroulette'}, exact: false},
          {title: this.$t('label.acroQuiz'), icon: 'mdi-help-circle', route: {name: 'acro-quiz'}, exact: false},
        ],
      },
      {
        title: this.$t('label.administration'), icon: 'mdi-security', open: this.isGroupOpen.admin,
        condition: this.$store.state.auth.isAdmin,
        children: [
          {title: this.$tc('p.user', 2), icon: 'mdi-account-multiple', route: {name: 'users'}, exact: false},
          {title: this.$tc('label.feedback'), icon: 'mdi-chat-alert', route: {name: 'feedback'}, exact: false},
          {title: this.$tc('p.comment', 2), icon: 'mdi-chat', route: {name: 'comments'}, exact: false},
          {title: 'Dev', icon: 'mdi-dev-to', route: {name: 'dev'}, exact: false},
        ],
      },
      {title: this.$tc('p.jam', 2), icon: 'mdi-calendar', route: {name: 'jams'}, exact: false},
      {title: this.$tc('p.reference', 2), icon: 'mdi-web', route: {name: 'references'}, exact: false},
      {title: this.$tc('p.community', 2), icon: 'mdi-account-group', route: {name: 'communities'}, exact: false},
    ].filter(item => item.condition === undefined || item.condition === true);
  }

  startsWithAny(input: string, starts: string | string[]) {
    starts = Array.isArray(starts) ? starts : [starts];
    return starts.reduce((result, start) => result || input.startsWith(start), false);
  }

  get show(): boolean {
    return this.$store.state.app.showNavigation;
  }

  set show(value: boolean) {
    this.$store.dispatch('app/setNavigation', value);
  }
}
